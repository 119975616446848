import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
  useContext,
} from "react";

type DropdownSwitcherContextType = {
  selected?: any | null;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  filteredList: any[];
  getUniqueValue: (item: any) => string | number;
  getLabelValue: (item: any) => string;
  groupedFilteredList: Record<string, any[]>;
  selectedOptionRef?: MutableRefObject<HTMLDivElement | null>;
  disabled?: boolean;
  hasError: boolean;
};

export const DropdownSwitcherContext =
  createContext<DropdownSwitcherContextType>({
    setSearchQuery: () => {},
    filteredList: [],
    groupedFilteredList: {},
    getUniqueValue: (item) => item,
    getLabelValue: (item) => item,
    hasError: false,
  });

export const DropdownSwitcherContextProvider = DropdownSwitcherContext.Provider;

export const useDropdownSwitcher = () => {
  const context = useContext(DropdownSwitcherContext);

  if (!context) {
    throw new Error(
      "`useDropdownSwitcher` must be used within a DropdownSwitcherContextProvider",
    );
  }

  return context;
};
