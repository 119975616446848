import { ReactElement } from "react";
import { Inline, Icon, Text } from "~/components/vendorUI";
import { css, cva } from "ui/css";
import { useDropdownSwitcher } from "../context/DropdownSwitcherContext";

interface TriggerProps<T>
  extends Omit<React.ComponentProps<typeof Text>, "children"> {
  placeholder: string;
  children?: ReactElement | ((item: T) => ReactElement);
}

const triggerButtonCSS = css({
  "@layer base": { all: "unset" },
  boxSizing: "border-box",
  borderRadius: "4px",
  backgroundColor: "gray.1",
  padding: "token(spacing.8px) 12px",
  transition: "background 0.25s",
  height: "40px",

  "&:hover": {
    backgroundColor: "brandBlue.1",
  },

  "&:focus-visible": {
    outline: "2px solid {colors.sky}",
  },
});

const overflowTextCSS = cva({
  base: {
    overflowX: "clip",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    flexShrink: 1,
    maxWidth: "276px",
    fontSize: "16px !",
  },
  variants: {
    isPlaceholder: {
      true: {
        color: "steel",
      },
    },
  },
});

export const Trigger = <T extends object>({
  placeholder,
  children,
  ...props
}: TriggerProps<T>): ReactElement => {
  const { selected, getLabelValue } = useDropdownSwitcher();

  if (!children) {
    const label = getLabelValue(selected);

    return (
      <Inline align="center" gap="8px" asChild>
        <button type="button" className={triggerButtonCSS}>
          <Text
            color="brand"
            weight="semibold"
            className={overflowTextCSS({ isPlaceholder: !label })}
            asChild
          >
            <div title={label} {...props}>
              {label ?? placeholder}
            </div>
          </Text>
          <Icon color="steel" name="ChevronDown" size="12px" />
        </button>
      </Inline>
    );
  }

  return typeof children === "function" ? children(selected) : children;
};
